import { getRootCategoryGuideId } from '../../utils/guideItem/getRootCategorySlug';
import getRadioContext from './getRadioContext';
import getQueryParams from './getRouteQueryParams';

// HELPER
// e.g. /podcasts/, /audiobooks/
export function getRootCategoryContext(props) {
  // exported for tests
  const {
    match: { url },
  } = props;

  if (!url) {
    return null;
  }

  const path = url.replace(/\//g, ''); // remove slashes
  const guideId = getRootCategoryGuideId(path);

  if (guideId) {
    return {
      title: path,
      guideId,
    };
  }

  return null;
}

// ROOT-CATEGORY CONNECTOR (RootCategoryConnector.js)
// e.g. /podcasts/, /audiobooks/
export function createRootCategoryContext(props) {
  const rootCategory = getRootCategoryContext(props);

  if (rootCategory) {
    const queryParams = getQueryParams(props, false);

    return {
      ...rootCategory,
      ...queryParams,
    };
  }

  return null;
}

// BROWSE COMPONENT (Browse.js)
// e.g. /radio/:guideIdentifier AND /podcasts/:podcastGuideIdentifier
// e.g. /podcasts/, /audiobooks/
export function createRadioBrowseContext(props) {
  const radioRouteContext = getRadioContext(props);
  if (radioRouteContext) {
    // e.g. /radio/:guideIdentifier
    return radioRouteContext; // already has query params
  }

  const rootCategory = getRootCategoryContext(props);

  if (rootCategory) {
    // e.g. /podcasts/
    const queryParams = getQueryParams(props, false);
    return {
      ...rootCategory,
      ...queryParams,
    };
  }

  return null;
}
