import cssVariables from './variables';

export default {
  border: `1px solid ${cssVariables['--border-color']}`,
  divider: {
    marginTop: cssVariables['--default-margin'],
    marginBottom: cssVariables['--header-margin'],
  },
  alignParent: {
    position: 'relative',
  },
  verticalAlignChild: {
    position: 'absolute',
    display: 'inline-block',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  horizontalBlockCenter: {
    marginRight: 'auto',
    marginLeft: 'auto',
  },
};
