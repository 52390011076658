import isEmpty from 'lodash/isEmpty';
import { selectIsFord } from './app';

const NAMESPACE = 'auth';

export const selectAuth = (state) => state?.[NAMESPACE] || {};
export const selectTokenInfo = (state) => state?.[NAMESPACE]?.tokenInfo || {};
export const selectIsAuthenticated = (state) =>
  !isEmpty(state?.[NAMESPACE]?.tokenInfo) || selectIsFord(state);
export const selectPinCodeVerification = (state) =>
  state?.[NAMESPACE]?.deviceDetails || {};
