import get from 'lodash/get';
import getRadioContext from './getRadioContext';
import getQueryParams from './getRouteQueryParams';

// HELPER
// e.g. /vanitySlug (station with custom url)
export function getVanityContext(props) {
  // exported for tests
  const vanitySlug = get(props, 'route.routeLevelProps.vanitySlug');

  if (vanitySlug) {
    return {
      guideId: get(props, 'route.routeLevelProps.guideId'),
      title: vanitySlug,
    };
  }

  return null;
}

// VANITY-PROFILE CONNECTOR (VanityProfileConnector.js)
// e.g. /vanitySlug (station with custom url)
export function createVanityProfileContext(
  props,
  includeProfileParams = false,
) {
  const vanityProfileRouteContext = getVanityContext(props);

  if (vanityProfileRouteContext) {
    // e.g. /:vanitySlug
    const queryParams = getQueryParams(props, includeProfileParams); // maybe just always true?

    return {
      ...vanityProfileRouteContext,
      ...queryParams,
    };
  }

  return null;
}

// PROFILE COMPONENT
// e.g. /radio/:guideIdentifier AND /podcasts/:podcastGuideIdentifier
export function createRadioProfileContext(props) {
  const radioRouteContext = getRadioContext(props);

  if (radioRouteContext) {
    return radioRouteContext; // already has query params
  }

  return createVanityProfileContext(props, true);
}
