import { NOW_PLAYING_GUIDE_ID } from '../constants/dialogs/nowPlaying';

const NAMESPACE = 'dialog';

export const selectIsNowPlayingDialogOpen = (state) =>
  state?.[NAMESPACE]?.isNowPlayingDialogOpen || false;
export const selectNowPlayingGuideItem = (state) =>
  state?.[NAMESPACE]?.[NOW_PLAYING_GUIDE_ID] || {};
export const selectNowPlayingProfileGuideItem = (state) =>
  selectNowPlayingGuideItem(state)?.containerItems?.[0]?.children?.[0];
export const selectIsDownloadAppDialogOpen = (state) =>
  state?.[NAMESPACE]?.isDownloadAppDialogOpen || false;
export const selectDidShowDownloadAppDialog = (state) =>
  state?.[NAMESPACE]?.didShowDownloadAppDialog || false;
export const selectIsTextMeTheAppDialogOpen = (state) =>
  state?.[NAMESPACE]?.isTextMeTheAppDialogOpen || false;
export const selectInterestSelectorCategoryId = (state) =>
  state?.[NAMESPACE]?.interestSelectorCategoryId || '';
export const selectIsInterestSelectorDialogOpen = (state) =>
  state?.[NAMESPACE]?.isInterestSelectorDialogOpen || false;
export const selectInAppMessageDialogContent = (state) =>
  state?.[NAMESPACE]?.inAppMessageDialogContent;
export const selectInAppMessageGuideId = (state) =>
  state?.[NAMESPACE]?.inAppMessageGuideId || '';
export const selectShouldInAppMessageDialogOpen = (state) =>
  state?.[NAMESPACE]?.shouldInAppMessageDialogOpen || false;
export const selectVideoAdDialogState = (state) =>
  state?.[NAMESPACE]?.videoAdDialog || {};
export const selectIsVideoAdDialogOpen = (state) =>
  state?.[NAMESPACE]?.videoAdDialog?.isOpen || false;
export const selectLearnMoreUrl = (state) =>
  state?.[NAMESPACE]?.videoAdDialog?.learnMoreUrl || '';
