const NAMESPACE = 'profiles';

export const selectAllProfiles = (state) => state?.[NAMESPACE];
export const getSelectProfile = (guideId) => (state) =>
  state?.[NAMESPACE]?.[guideId];
export const selectHasTopicFetchFailed = (state, topicGuideId) =>
  !!state?.[NAMESPACE]?.[topicGuideId]?.hasFailed || false;
export const getSelectProfileIsFollowing = (guideId) => (state) =>
  getSelectProfile(guideId)(state)?.actions?.follow?.isFollowing;
export const getSelectProfileClassification = (guideId) => (state) =>
  getSelectProfile(guideId)(state)?.metadata?.properties?.classification;
