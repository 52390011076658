import isSubscribedThroughWeb from '../utils/subscription/subscribedThroughWeb';
import { premiumSubscriptionStatus } from '../utils/subscription/subscriptionStatus';
import { selectIsFord } from './app';

const NAMESPACE = 'me';

export const selectUserDetails = (state) => state?.[NAMESPACE]?.details;

export const selectSubscriptionStatus = (state) =>
  state?.[NAMESPACE]?.details?.subscriptionStatus;

export const selectSubscriptionProviderName = (state) =>
  state?.[NAMESPACE]?.details?.subscriptionProviderName;

export const selectIsUserSubscribed = (state) =>
  selectSubscriptionStatus(state) === premiumSubscriptionStatus.active ||
  selectIsFord(state);

export const selectIsExpiredWebSubscriber = (state) => {
  const status = selectSubscriptionStatus(state);
  const provider = selectSubscriptionProviderName(state);

  const hasExpiredStatus = status === premiumSubscriptionStatus.expired;
  // has expired web subscription status

  // the user data does not contain if the user is Active but has cancelled their subscription
  // we check this when they try to subscribe or go to their account subscription tab... they will
  // get redirected accordingly at that point
  return hasExpiredStatus && isSubscribedThroughWeb(provider);
};

export const selectUserAccountId = (state) =>
  state?.[NAMESPACE]?.details?.accountId;

export const selectUsername = (state) => state?.[NAMESPACE]?.details?.id;
export const selectUserEmail = (state) => state?.[NAMESPACE]?.details?.email;
export const getSelectChangedFavoritesForGuideId = (guideId) => (state) =>
  state?.[NAMESPACE]?.changedFavorites?.[guideId];
