import get from 'lodash/get';
import { createSelector } from 'reselect';

const NAMESPACE = 'loginDetails';

export const selectLoginDetails = (state) => get(state, NAMESPACE, {});
export const selectAuthViewType = createSelector(
  [selectLoginDetails],
  (loginDetails) => get(loginDetails, 'viewType'),
);
