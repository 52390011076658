import parseGuideIdentifier from '../../utils/parseGuideParams';
import getQueryParams from './getRouteQueryParams';

// RADIO CONNECTOR (and helper to other connectors in this file) (RadioConnector.js)
// PROFILE CONNECTOR (does not need vanity support)
// e.g. /radio/:guideIdentifier AND /podcasts/:podcastGuideIdentifier
export default function getRadioContext(props) {
  const {
    match: { params },
  } = props;
  const { guideIdentifier, podcastGuideIdentifier } = params;
  const identifier = guideIdentifier || podcastGuideIdentifier;

  if (identifier) {
    const radioUrlParams = parseGuideIdentifier(identifier);
    const queryParams = getQueryParams(props, true);

    return {
      ...radioUrlParams, // guideId and title
      ...queryParams,
    };
  }

  return null;
}
