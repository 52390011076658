const playerPlayBtnSize = 45;
const playerHeight = 56;
const playerHeightSmall = 88;
const defaultMargin = 12;
const sidePaddingSmall = 16;
const leftSidePaddingMedium = 32;
const rightSidePaddingMedium = 52;
const sidePaddingRightScrollbarMed = 45;
const bannerVerticalPaddingSmall = 32;
const formItemFontSize = 14;
const formItemHeight = 40;
const formItemBorderRadius = 3;
const leftSideWidth = 218;
const rightSideLeftPadding = 4;
const browserMaxScrollbarOffset = 17;
const rightSideWidth = 300;
const rightSideWidthPadding =
  rightSideWidth + rightSideLeftPadding + browserMaxScrollbarOffset;
const mainContentMaxWidth = 1833;
const headerHeight = 65;
const contentAreaSpacing = 18;
const mainContentMaxWidthClosedRight = mainContentMaxWidth - leftSideWidth;
const mainContentMaxWidthOpenSidebars =
  mainContentMaxWidth -
  (leftSideWidth + rightSideWidth + browserMaxScrollbarOffset);

// Figtree-Regular is local installed font on some devices -- added to support mobile webviews
const baseFonts = ['Figtree', 'Figtree-Regular', 'system-ui', 'sans-serif'];
const primaryFont = [...baseFonts];
const primaryFontHeader = ['GT-Pressura-Ti-Regular', ...primaryFont];
const primaryFontHeaderBold = ['GT-Pressura-Ti-Bold', ...primaryFont];
const auxiliaryFontHeader = [...primaryFont];

// Convert arrays to strings
const primaryFontStr = primaryFont.join(', ');
const primaryFontHeaderStr = primaryFontHeader.join(', ');
const primaryFontHeaderBoldStr = primaryFontHeaderBold.join(', ');
const auxiliaryFontHeaderStr = auxiliaryFontHeader.join(', ');
const monoFontStr = '"Roboto Mono", monospace';

const antiFlashWhite = '#F1F2F3';

export default {
  // COLORS
  // teals
  '--primary-color-1': '#36b4a7',
  '--primary-color-2': '#28877d',
  '--primary-color-3': '#459FBF',
  '--primary-color-4': '#00AAA0',
  '--t-sharp': '#14d8cc',
  '--disabled-t-sharp': '#72e7e0',

  '--ink-extra-light': '#606376',
  '--ink-light': '#4b4f6b',
  '--ink-med': '#333751',
  '--ink-med-dark': '#4a4d64',
  '--ink-dark': '#0b0e24',
  '--space-cadet-blue': '#282c58',
  '--yankees-blue': '#1c1f3a',
  '--dark-blue': '#1c1e3e',
  '--carolina-blue': '#91CDE9',

  '--anti-flash-white': antiFlashWhite,

  '--content-area-background-color-hex': antiFlashWhite, // Global UI Refactor - Main App BackgroundColor

  // black to grey
  '--secondary-color-1': '#37444e', // dark blue
  '--secondary-color-2': '#a7a7a7',
  '--secondary-color-3': '#7c7c7c',
  '--secondary-color-4': '#858585',
  '--secondary-color-5': '#1c203c', // ink
  '--secondary-color-6': '#a8a8a8',
  '--secondary-color-7': '#33364F', // light ink
  '--ink-11': '#131730',
  '--medium-grey': '#383A3F',
  '--dark-grey': '#26282B',
  '--very-dark-grey': '#1E1F22',

  '--black': '#000000', // black
  '--sky': '#7fcfec',
  '--sky-hover': '#91dcf7',
  '--sky-active': '#aae3f8',
  '--sky-vibrant': '#05A8FC',

  // greys
  '--grey-1': '#9d9ea9',
  '--grey-2': '#eeefee',
  '--grey-3': '#f6f8f6',
  '--grey-4': '#dadada',
  '--grey-5': '#F2F2F5',
  '--grey-6': '#8A8D9E',
  '--grey-7': '#575757',
  '--grey-8': '#303030',
  '--text-grey': '#6e6e84',
  '--tunein-grey': '#fafafa',
  '--cloud-grey': '#7E808F',
  '--elephant-grey': '#CBCCD2',
  '--knighthood-grey': '#44475D',
  '--grey-poupon': '#DEDFE2',
  '--51st-shade-of-grey': '#A4A6B0',
  '--tunein-grey-border-light': '#dbdbdb',
  '--tunein-grey-active': '#f8f8f8',
  '--tunein-container-item-border-grey': 'rgba(186, 186, 186, 0.25)',
  '--modal-input-color': '#F2F2F5',
  '--grey-hover': '#f7f8f6',
  '--disabled-grey': '#DEDFE6',

  // Numbered Lists
  '--index-number-light': '#ababab',
  '--numbered-element-subtitle-color': '#a4a6b1',
  '--numbered-element-border-color': '#ebebeb',

  // Currently used in:
  // - PillButton
  // - Left sidebar background
  // footer social icons bg
  '--tunein-grey-2': '#e6e6e6',

  // Currently used in:
  // - PillButton
  '--tunein-grey-3': '#efefef',
  '--white': '#FFFFFF',

  '--compact-grey': '#f1f1f1',

  '--border-color': '#c8c8c8',
  '--border-light-color': '#d6d6d6',
  '--light-icon-color': '#AFAFAF',

  '--darken-hover-color': 'rgba(0, 0, 0, 0.15)',

  '--bg-color-1': '#2c9a8f',

  '--premium-gold': '#d9962f',
  '--premium-gold-2': '#e49a24',
  '--premium-gold-2-hover': '#F1A530',
  '--premium-gold-light': '#fec25a',
  '--premium-gold-darken': '#b47a21',
  '--gold-light': '#fecb72',
  '--paypal-gold': '#ffc439',

  // some red
  '--tunein-coral': '#fd685f',
  '--tunein-coral-light': '#fd8c85',
  '--tunein-coral-hover': '#ff8b85',
  '--tunein-coral-soft': '#f26961',
  '--error-color': '#d0021b',
  '--error-color-2': '#c40909',
  '--error-color-light': '#e07577',

  '--dark-sub-text': '#9b9b9b',

  '--off-white': '#F4F4F6',

  '--tan': '#DFC596',

  '--divider': '#e6e6e6',

  '--enabled-green': '#49c46d',

  '--discord-purple': '#4E5AE8',
  '--facebook-color': '#3b5998',
  '--facebook-color-hover': '#344c80',
  '--facebook-color-active': '#2f436c',
  '--upsell-button-color': '#000000',
  '--upsell-button-hover': '#4c4c4c',

  '--lighter-hover-luminosity-value': -0.2,
  '--lighter-disabled-opacity-value': 0.4,
  '--dialog-default-opacity-value': '0.54',

  '--home-page-header-menu-color': 'var(--grey-8)',
  '--home-page-header-menu-hover': 'var(--grey-7)',

  '--user-header-image-border': '#e3e4e3',
  '--user-header-ink-medium': '#33364f',
  '--user-header-ink-dark': '#1b203b',

  '--color-background': '#f1f2f3',
  '--text-color': 'var(--secondary-color-5)',
  '--button-label-color': 'var(--anti-flash-white)',
  '--sub-text-color': 'var(--cloud-grey)',
  '--input-bg-color': 'var(--grey-poupon)',
  '--input-font-color': 'var(--secondary-color-5)',
  '--input-search-icon': 'var(--cloud-grey)',
  '--search-bar-container-margin-top': '39px',
  '--content-area-spacing-first-child': 'var(--content-area-spacing)',

  // FONTS
  '--primary-font': primaryFontStr,
  '--primary-font-header-bold': primaryFontHeaderBoldStr,
  '--primary-font-header': primaryFontHeaderStr,
  '--auxiliary-font-header': auxiliaryFontHeaderStr,
  '--mono-font': monoFontStr,

  // FONT SIZES
  '--font-size-1': '12px',
  '--font-size-2': '14px',
  '--font-size-3': '16px',
  '--font-size-4': '18px',
  '--font-size-5': '20px',
  '--font-size-6': '24px',
  '--font-size-7': '30px',
  '--font-size-8': '36px',
  '--font-size-9': '42px',

  '--primary-button-letter-spacing': '3px',
  '--guide-sub-line-height': '15px',

  '--list-item-title-line-height': '19px',
  '--list-item-sub-line-height': '16px',

  '--default-button-icon-size': 12,
  '--small-label-font-size': '11px',

  '--form-item-font-size': formItemFontSize,
  '--form-item-font-size-px': `${formItemFontSize}px`,

  // FONT WEIGHTS
  '--font-weight-light': '300',
  '--font-weight-regular': '400',
  '--font-weight-medium': '500',
  '--font-weight-bold': '600',
  '--font-weight-bolder': '700',

  // LETTER SPACING
  '--letter-spacing-normal': '0.03em',
  '--letter-spacing-loose': '0.09em',

  // HEIGHTS/WIDTHS
  // playerHeight is still used in a regular css file so need the px
  '--browser-max-scrollbar-width': browserMaxScrollbarOffset,
  '--player-height': `${playerHeight}px`,
  '--player-height-small': `${playerHeightSmall}px`,
  '--player-play-btn-size': `${playerPlayBtnSize}px`,
  '--player-timestamp-width': '56px',

  '--site-header-height': `${headerHeight}px`,
  '--rightSide-left-padding': rightSideLeftPadding,
  '--rightSide-width': rightSideWidth + rightSideLeftPadding,
  '--leftSide-width': leftSideWidth,
  '--branch-banner-height': '76px',

  // these are specifically for the padding around the main content
  '--rightSide-width-padding-px': `${rightSideWidthPadding}px`,
  '--leftSide-width-px': `${leftSideWidth}px`,

  '--content-area-spacing': `${contentAreaSpacing}px`,

  // right side ad display drawer
  '--right-side-top-padding': `${headerHeight + contentAreaSpacing}px`,
  '--right-side-right-padding': 0,
  '--right-side-bottom-padding': '20px',

  '--default-margin': defaultMargin,
  '--extra-margin': defaultMargin * 2,

  '--content-max-width-px': `${mainContentMaxWidthOpenSidebars}px`,
  '--content-max-width-closedright-px': `${mainContentMaxWidthClosedRight}px`,

  '--content-side-padding-px-small': `${sidePaddingSmall}px`,
  '--content-side-left-padding-px-med': `${leftSidePaddingMedium}px`,
  '--content-side-right-padding-px-med': `${rightSidePaddingMedium}px`,
  '--content-side-padding-right-scrollbar-px-med': `${sidePaddingRightScrollbarMed}px`,
  '--content-side-margin-px-small': `-${sidePaddingSmall}px`,
  '--content-side-left-margin-px-med': `-${leftSidePaddingMedium}px`,
  '--content-side-right-margin-px-med': `-${rightSidePaddingMedium}px`,
  '--content-side-margin-right-scrollbar-px-med': `-${sidePaddingRightScrollbarMed}px`,

  '--banner-vertical-padding-small': `${bannerVerticalPaddingSmall}px`,

  '--default-margin-px': `${defaultMargin}px`,
  '--field-margin': '20px',
  '--header-margin': '36px',

  // Spacing below container item group items
  '--gallery-item-side-padding-px': '12px',
  '--brick-tile-bottom-padding-px': '0px',

  '--full-screen-splash-z-index': 2000,
  '--auth-dialog-z-index': 1700,
  '--now-playing-ad-z-index': 1600,
  '--above-top-z-index': 1550,
  '--top-z-index': 1500,
  '--app-z-index': 1400,
  '--tile-z-index-1': 10,
  '--tile-z-index-2': 20,

  // Borders
  '--tile-border-radius': 4,

  // forms
  '--form-item-height': formItemHeight,
  '--form-item-height-px': `${formItemHeight}px`,
  '--form-item-border-radius': formItemBorderRadius,
  '--form-item-border-radius-px': `${formItemBorderRadius}px`,
  '--form-max-width-px': '624px',
  '--form-title-font-color': 'black',
  '--form-title-font-size-lg': '42px',

  '--small-form-item-height': '25px',

  '--hover-lum-setting': '15%',
  '--header-user-thumbnail-size': '88px',
  '--header-thumbnail-size': '102px',
  '--header-thumbnail-size-90': '90px',
  '--header-thumbnail-size-120': '120px',
  '--header-thumbnail-size-210': '210px',

  '--padding-small': '18px',
  '--padding-medium': '24px',
  '--search-bar-width': '287px',
  '--compact-container-padding': '21px',

  '--small-button-height': '27px',
  '--regular-button-height': '36px',
  '--large-button-height': '43px',

  '--content-sub-header-spacing': '12px',
  '--gallery-content-sub-header-spacing': '5px',

  '--container-item-y-spacing': '20px',

  // Icons
  '--small-icon-size': '18px',
  '--medium-icon-size': '22px',
  '--large-icon-size': '30px',

  // TRANSITIONS/TRANFORMS
  '--base-transition': 'all 0.2s ease 0ms',

  '--zdepth1-box-shadow':
    '0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.12)',
  '--zdepth2-box-shadow':
    '0 3px 10px rgba(0, 0, 0, 0.16), 0 3px 10px rgba(0, 0, 0, 0.23)',
  '--zdepth3-box-shadow':
    '0 10px 30px rgba(0, 0, 0, 0.19), 0 6px 10px rgba(0, 0, 0, 0.23)',

  standardMuiZDepth: 3,

  '--landing-search-offset': '48px',
  '--landing-max-width': '1120px',
  '--landing-border-radius': '3px',

  // top banner height
  '--top-banner-height': '64px',
  '--info-banner-height-baseline': '26px',
  '--info-banner-height': '26px',

  // used in landing.css and optout.css
  '--main-nav-links-margin-left': '32px',
  '--main-nav-links-header-button-margin-right': '30px',
  '--main-nav-links-header-button-width': '111px',

  '--tall-page-header-content-padding': '20px',
  '--tall-page-header-content-padding-large': '35px',
  '--auth-form-max-width': '775px',

  '--gallery-page-transition-delay': 250,

  // Device List spacing on Account Settings > Devices
  '--device-list-margin': '16px',

  '--tablet-social-control-width': '110px',
  '--mobile-social-control-width': '100px',

  '--map-view-filters-left-offset': '303px',
  '--map-view-overlay-component-background-color': 'rgb(241 242 243 / 20%)',
  '--map-view-overlay-component-backdrop-filter': 'blur(25px)',
};
