import get from 'lodash/get';
import parseQuery from 'src/common/utils/queryString/parse';

// HELPER
// parse and return query params
// with optional profile query params
export default function getQueryParams(props, withProfileParams) {
  const { location, history } = props;

  if (!location) {
    return {};
  }

  let locationInfo = location;
  // this is to get past what seems to be a bug in react-router 4
  // if the location.pathname stays the same, but the query params
  // change, the location prop does indicate a route change
  // However, the history is accurate everywhere it needs to be
  // So here, we are just checking to see if there is anything in the
  // querystring and then using that location object so that we can
  // read it.
  if (get(history, 'location.search')) {
    locationInfo = history.location;
  }

  let query = locationInfo.query || {};

  if (locationInfo.search) {
    // react-router on the client user the search property
    query = parseQuery(locationInfo.search);
  }

  const defaultParams = {
    attributes: get(query, 'attributes'),
    itemToken: get(locationInfo, 'state.itemToken'),
  };

  if (withProfileParams) {
    const { topicid, audioclipid } = query;

    return {
      ...defaultParams,
      topicGuideId: topicid ? `t${topicid}` : topicid,
      audioClipId: audioclipid ? `i${audioclipid}` : audioclipid,
    };
  }

  return defaultParams;
}
