import map from 'lodash/map';
import vanityUrlRedirects from '../../data/vanityUrlRedirects.json';
import VanityProfileConnector from '../components/profile/VanityProfileConnector';

const vanityRoutes = map(
  vanityUrlRedirects.guideIdsToPathnames,
  (value, key) => ({
    path: `/${value}`,
    component: VanityProfileConnector,
    exact: true,
    routeLevelProps: {
      isOverlayablePage: true,
      vanitySlug: value,
      guideId: key,
    },
  }),
);

export default vanityRoutes;
