import { AUTOPLAY_GUIDE_ID, BROWSIES_GUIDE_ID } from '../constants/categories';

const NAMESPACE = 'categories';

export const getSelectCategory = (guideId) => (state) =>
  state?.[NAMESPACE]?.[guideId];
export const selectAutoPlayCategory = (state) =>
  state?.[NAMESPACE]?.[AUTOPLAY_GUIDE_ID];
export const selectBrowsiesCategory = (state) =>
  state?.[NAMESPACE]?.[BROWSIES_GUIDE_ID];
