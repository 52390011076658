import commonStyles from './common';
import cssVariables from './variables';

export default {
  pairingTextField: {
    fontSize: '22px',
    color: 'white',
    fontFamily: cssVariables['--auxiliary-font-header'],
    border: 'none',
    borderBottom: `1px solid ${cssVariables['--ink-med-dark']}`,
    height: '38px',
    backgroundColor: `${cssVariables['--ink-11']}`,
  },
  pageSubtitle: {
    fontSize: cssVariables['--font-size-3'],
    color: cssVariables['--secondary-color-3'],
  },

  // Form inputs
  // Specific to MUI TextFields
  textField: {
    fontSize: cssVariables['--form-item-font-size'],
    height: cssVariables['--form-item-height'],
    width: '100%',
    transition: 'all 450ms linear 0',
    backgroundColor: cssVariables['--modal-input-color'],
  },
  textFieldFocused: {
    outline: 'none',
    border: commonStyles.border,
  },
  textFieldHint: {
    color: cssVariables['--ink-extra-light'],
    fontFamily: cssVariables['--primary-font'],
    bottom: 'initial',
    padding: '0 0 0 12px',
    ...commonStyles.verticalAlignChild,
  },
  textFieldInput: {
    padding: '0 0 0 12px',
    color: 'inherit',
  },
  textFieldInputBold: {
    fontFamily: cssVariables['--primary-font-med'],
  },
  errorState: {
    border: `1px solid ${cssVariables['--error-color']}`,
  },
  pairingErrorState: {
    border: 'none',
    borderBottom: `1px solid ${cssVariables['--error-color']}`,
  },
  inlineError: {
    fontFamily: cssVariables['--primary-font-thin'],
    color: cssVariables['--error-color-light'],
    top: 0,
    lineHeight: '13px',
    fontSize: 12,
    paddingTop: 5,
  },
  label: {
    top: 9,
    left: 10,
    fontSize: 17,
  },
  floatingLabelShrink: {
    top: 2,
    left: 0,
    fontSize: 17,
  },
  disabledState: {
    color: cssVariables['--dark-sub-text'],
    backgroundColor: cssVariables['--tunein-grey'],
  },
};
