import get from 'lodash/get';
import { createSelector } from 'reselect';

import validateCarouselItems from '../components/utils/carouselValidator';
import { selectIsSubscriptionEnabled } from './config';
import { selectIsUserSubscribed } from './me';

const NAMESPACE = 'heroCarousel';

export const selectHeroCarouselItems = (state) =>
  get(state, [NAMESPACE, 'carouselItems'], []);

export const selectValidatedHeroCarouselItems = createSelector(
  [
    selectHeroCarouselItems,
    selectIsUserSubscribed,
    selectIsSubscriptionEnabled,
  ],
  (heroCarouselItems, isUserSubscribed, isSubscriptionEnabled) => {
    const shouldShowPremiumUpsell = isSubscriptionEnabled && !isUserSubscribed;

    return validateCarouselItems(heroCarouselItems, shouldShowPremiumUpsell);
  },
);
