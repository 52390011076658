import viewTypes from 'src/common/constants/auth/viewTypes';
import Redirect from '../components/Redirect';

import { LOGIN_PATH, PAYMENT_PATH } from '../constants/paths';

const legacyRedirects = [
  {
    path: '/subscribe/cancel',
    component: Redirect,
    routeLevelProps: { redirectPath: PAYMENT_PATH },
  },
  {
    path: '/subscribe/finalcancel',
    component: Redirect,
    routeLevelProps: { redirectPath: PAYMENT_PATH },
  },
  {
    path: '/account/new/login',
    component: Redirect,
    routeLevelProps: { redirectPath: LOGIN_PATH },
  },
  {
    path: '/account/new/forgot',
    component: Redirect,
    routeLevelProps: {
      redirectPath: LOGIN_PATH,
      query: {
        vt: viewTypes.forgotPassword,
      },
    },
  },
  {
    path: '/account/newiframe/signup',
    component: Redirect,
    routeLevelProps: {
      redirectPath: LOGIN_PATH,
      query: {
        vt: viewTypes.signUp,
      },
    },
  },
  {
    path: '/account/newiframe/login',
    component: Redirect,
    routeLevelProps: { redirectPath: LOGIN_PATH },
  },
];

export default legacyRedirects;
