export default {
  XXXLARGE: 1675,
  XXLARGE: 1420,
  XLARGE: 1192,
  LARGE: 992,
  BREAKPOINT_896: 896,
  BREAKPOINT_812: 812,
  MEDIUM: 768,
  MEDIUM_MINUS_1: 767,
  BREAKPOINT_736: 736,
  BREAKPOINT_667: 667,
  BREAKPOINT_650: 650,
  SMALL: 640,
  BREAKPOINT_500: 500,
  XSMALL: 415,
  XSMALL_MINUS_1: 414,
  BREAKPOINT_375: 375,
  BREAKPOINT_360: 360,
  XXSMALL: 321,
  DISCORD_PICTURE_IN_PICTURE_MEDIUM: 200,
  DISCORD_PICTURE_IN_PICTURE_SMALL: 150,
  MIN: 0,
};
