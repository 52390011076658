import { applyMiddleware, createStore } from 'redux';
import promiseMiddleware from 'redux-promise-middleware';
import thunkMiddleware from 'redux-thunk';
import errorCatcher from '../middleware/errorCatcher';
import reducers from '../reducers';
import { isDevBuild } from '../utils/environment';

export default function configureStore(
  initialState,
  envMiddleware = [],
  apiClientEffectsMiddleware,
  errorCallback,
) {
  const middleware = [
    errorCatcher(errorCallback), // handles null actions as well as errors
    thunkMiddleware, // this should always go first
    apiClientEffectsMiddleware, // make sure this is before promiseMiddleware
    promiseMiddleware,
    ...envMiddleware,
  ];

  const store = createStore(
    reducers,
    initialState,
    applyMiddleware(...middleware),
  );

  if (isDevBuild() && module.hot) {
    module.hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers').default; // eslint-disable-line global-require
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
