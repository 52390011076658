import { isCategory, isProfile } from '../../utils/guideItemTypes';
import { getGuideIdFromGuideIdentifier } from '../../utils/parseGuideParams';

function guideIdentifierValidator(params = {}, validator) {
  const { guideIdentifier } = params;
  const guideId = getGuideIdFromGuideIdentifier(guideIdentifier);

  if (!guideId) return false;

  return validator(guideId);
}

export function radioRouteValidator(params) {
  return guideIdentifierValidator(
    params,
    (guideId) => isCategory(guideId) || isProfile(guideId),
  );
}
